import { DisplayViewData } from '@/components/BlockArrayWrapper/TextBlockWithImage/DisplayViews/displayViews.model'
import { USBColumn, USBGrid } from '@usb-shield/react-grid'
import {
  defaultColLayoutOptions,
  defaultGridOptions,
} from '@/utils/usb-grid.util'
import styles from '@/components/BlockArrayWrapper/TextBlockWithImage/DisplayViews/styles/twoColBannerTitleImage.module.scss'

let gridSettings = defaultGridOptions

const fullWidthColumn: Object = {
  ...defaultColLayoutOptions,
  spans: {
    xlarge: 12,
    large: 12,
    medium: 8,
    small: 4,
  },
}

const contentColumn: Object = {
  ...defaultColLayoutOptions,
  spans: {
    xlarge: 6,
    large: 6,
    medium: 8,
    small: 4,
  },
}

const contentBlock: Object = {
  ...defaultColLayoutOptions,
  spans: {
    xlarge: 6,
    large: 6,
    medium: 8,
    small: 4,
  },
  justify: 'stretch',
  padding: 'zero',
}

const contentBlockDim: Object = {
  ...defaultColLayoutOptions,
  spans: {
    xlarge: 8,
    large: 8,
    medium: 8,
    small: 4,
  },
  justify: 'stretch',
}

const TwoColBannerTitleImage = ({
  title,
  shortDescription,
  image,
  linkAsButton,
  displayView,
  levelDownHeads,
  fourUp,
  levelUpHeads,
}: DisplayViewData) => {
  let textColumn: any = '',
    renderImageLeft: any = '',
    textColClass: any = '',
    imageColClass: any = '',
    renderImageRight: any = ''

  switch (displayView) {
    case 'TwoColBannerTitleImageLeft':
      imageColClass = styles.imageDisplayImageLeftLayout
      textColClass = styles.textDisplayImageLeftLayout
      renderImageLeft = GetImageColumn()

      break

    case 'TwoColBannerTitleImageRight':
      imageColClass = styles.imageDisplayImageRightLayout
      textColClass = styles.textDisplayImageRightLayout
      renderImageRight = GetImageColumn()

      break
  }

  function GetImageColumn() {
    return (
      <USBColumn layoutOpts={contentBlock} addClasses={imageColClass}>
        <div className={styles.imageWrapper} data-testid="image">
          <img src={image} alt="" />
        </div>
      </USBColumn>
    )
  }

  return (
    <>
      <USBGrid
        addClasses={styles.gridStyles}
        gridGap={gridSettings.gridGap}
        alignItems={gridSettings.alignItems}
        columnCount="12"
        justifyContent={gridSettings.justifyContent}
        display={gridSettings.display}
        padding={'zero'}
        type={gridSettings.type}
      >
        {renderImageLeft}

        <USBColumn layoutOpts={fullWidthColumn}>
          {levelUpHeads ? (
            <h2 className={styles.headH2NoBottomMargin}>{title}</h2>
          ) : levelDownHeads ? (
            <h4 className={styles.headH2NoBottomMargin}>{title}</h4>
          ) : (
            <h3 className={styles.headH2NoBottomMargin}>{title}</h3>
          )}
        </USBColumn>
        <USBColumn layoutOpts={contentBlock} addClasses={textColClass}>
          <div
            id={styles.paragraph}
            className={styles.paragraph}
            dangerouslySetInnerHTML={{ __html: shortDescription }}
          />
        </USBColumn>

        {renderImageRight}
      </USBGrid>
    </>
  )
}

export default TwoColBannerTitleImage
